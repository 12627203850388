import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Gallery from "react-grid-gallery";

import Layout from "../components/layout";
import BannerLineas from "../components/BannerLineas";
import ProductoRelacionado from "../components/ProductoRelacionado";

import { listArticulos, getArticulo, getRelacionados } from "../utils/articulos";

import "../assets/css/lucero.css";

const imgArticulos = [];
listArticulos("infantil").forEach(a => {
  imgArticulos.push({
    src: a.img,
    thumbnail: a.img,
    thumbnailWidth: a.width ? a.width : 266,
    thumbnailHeight: 178,
    thumbnailCaption: "Art. " + a.codigo,
    caption: a.descripcion,
    alt: a.descripcion,
  });
});
const relacionados = getRelacionados("bazar");

const LineaInfantil = props => (
  <Layout>
    <Helmet>
      <title>Productos infantiles - Plasticos Lucero</title>
      <meta
        name="description"
        content="Productos plasticos infantiles que tienen utilidad, desde los primeros meses de vida hasta el comienzo de etapa escolar"
      />
    </Helmet>

    <BannerLineas
      title="Linea Infantil"
      subTitle="Productos que tienen utilidad, desde los primeros meses de vida hasta el comienzo de etapa escolar"
      image={getArticulo("infantil", "290-320").img}
    />

    <div id="main">
      <section id="one">
        <div className="content">
          <div className="inner">
            <div
              style={{
                display: "block",
                minHeight: "1px",
                width: "100%",
                border: "1px solid #2A2F4A",
                overflow: "auto",
                textAlign: "center",
                background: "#2A2F4A",
              }}>
              <Gallery
                images={imgArticulos}
                enableImageSelection={false}
                backdropClosesModal={true}
                imageCountSeparator="&nbsp;de&nbsp;"
                showCloseButton={false}
                margin={15}
              />
            </div>
          </div>
        </div>
      </section>
      <section id="moreBazar">
        <div className="inner">
          <header className="major">
            <h2>Mirá la linea bazar tambien!</h2>
          </header>
          <div className="grid-wrapper">
            {relacionados.map(pr => (
              <ProductoRelacionado articulo={pr} key={pr.codigo} />
            ))}
          </div>
          <ul
            className="actions"
            style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 15 }}>
            <li>
              <Link to="/bazar" className="button next">
                VER MAS
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </Layout>
);

export default LineaInfantil;
